// Alerts
.alert {
    width: 100%;
    display: block;
    font-size: 1em;
    line-height: 1.5em;
    font-weight: bold;
    color: $bblack;
    margin: .25em 0 .75em 0;
    padding: .25em 2%;

    &.alert-success { color: $bsuccess !important; }
    &.alert-danger { color: $bdanger !important; }
    &.alert-warning { color: $bwarning !important; }

    &.alert-success-border { border: 3px solid $bsuccess !important; }
    &.alert-danger-border { border: 3px solid $bdanger !important; }
    &.alert-warning-border { border: 3px solid $bwarning !important; }

    ul {
        li {
            margin-left: 1em;
            line-height: 1.5em;
        }
    }

    a { color: inherit; }
}
