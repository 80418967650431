.carousel {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 100%;

    .carousel-cell {
        width: 100%;
        height: 100%;
        margin: 0;
        img { width: 100%; }
    }
}
