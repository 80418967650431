.page {
    position: relative;
    margin-left: 25em;
    background-color: $bwhite;
    &:before {
        position: fixed;
        top: 0; left: 0;
        width: 25em;
        height: 100%;
        display: block;
        content: '';
        background-color: $glighter;
    }
}

.navigation {
    margin: 4em 0;

    ul { display: flex; flex-flow: row wrap; }

    &__item {
        font-size: em(13);
        font-weight: $fwr;
        @include lund($bblack);
        &:before {
            content: '|';
            display: inline-block;
            margin: 0 1em;
        }
        &.inactive { pointer-events: none; opacity: .5; }

        &:first-child:before { display: none; }
    }
}

.content {
    &__title {
        margin-bottom: 1em;
        font-size: em(30);
        font-weight: $fwr;
        line-height: 1.2em;
        text-transform: uppercase;
    }

    &__copy {
        font-size: em(13);
        font-weight: $fwr;
        font-family: $sfaf;
        line-height: 1.5em;
        p { margin-bottom: 2em; }
        @include lund($bblack);
    }

    figure.image {
        position: relative;
        max-width: 100%;
        display: block;
        margin: .5em auto;
        &.video-embed {
            margin: 2em 0;
            padding-bottom: 56.25%;
            padding-top: 25px;
            height: 0;
            iframe {
                position: absolute; top: 0; left: 0;
                width: 100%;
                height: 100%;
            }
        }
        img {
            max-width: 100%;
            position: relative;
            z-index: 200;
            height: auto;
            display: block;
        }
        figcaption {
            margin: 0;
            padding: 7px;
            background: $glighter;
            font-size: .85em;
            text-align: center;
        }
    }
}

.gallery {
    margin-top: 2em;
    &__item {
        margin: .5em 0;
        &:first-child { margin-top: 0; }
        &:last-child { margin-bottom: 0; }
    }
    img { display: block; }
}

.contact {
    margin-top: 2em;
    border: 1px solid $glight;

    &__header {
        position: relative;
        z-index: 0;
        padding: 1em 1.25em;
        background-color: $glight;
        &:before {
            position: absolute;
            top: 100%; left: 1em; z-index: 1;
            content: '';
            @include triangle ("down", 1em, $glight);
        }
    }

    &__header-title {
        font-size: em(33);
        font-weight: $fwr;
        line-height: 1.2em;
    }

    &__form {
        padding: 2.5em;
        background-color: $glighter;
    }
}
