#ajax-home {
    margin-top: 3em;
}

.menu-container {
    position: relative;
    overflow: hidden;

    ul {
        margin-top: 1em;

        li {
            display: block;
            margin: .25em 0;
            // &:first-child { margin-top: 0; }
            // &:last-child { margin-bottom: 0; }
            a {
                display: block;
                padding: .35em .75em;
                color: $bwhite;
                font-size: em(12);
                line-height: 1.3em;
                text-transform: uppercase;
                background-color: $btn-primary;
                &:hover {
                    color: $bwhite;
                    background: lighten($btn-primary, 10%);
                }
            }

            &.active a {
                color: $bblack;
                background-color: $bwhite;
            }
        }
    }

    &__title {
        display: block;
        color: $bwhite;
        padding: 1em .75em;
        font-size: em(12);
        font-weight: $fwr;
        line-height: 1.3em;
        text-transform: uppercase;
        margin-top: .25em;
        background-color: rgba(#000, .15);
    }
}
