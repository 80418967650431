// Base variables
$base-wrapper:              800px;
$body-bgc:                  #fff;

// Brand Colors
$bwhite:                    #fff;
$bblack:                    #4a4a4a;

$bprimary:                  #22d0f5;

$bsecondary:                #333a3f;

$btn-primary:               rgba(#000, .57);

$bhighlight:                $bblack;

$bsuccess:                  #4eb510;
$bwarning:                  #e48113;
$bdanger:                   #df1b24;
$bdanger:                   #c2101d;
$status-colors:             $bdanger, $bsuccess, $bwarning;

// $gbase:                     #a8a8a8;
// $gdark:                     #595959;
$gdarker:                   #383838;
// $gdarkest:                  #4c4c4c;
$glight:                    #ddd;
$glighter:                  #efefef;
// $glightest:                 #fafafa;

// $gray:                      $gdarker;

$tc-link:                   $bhighlight;

$shadow:                    rgba(0, 0, 0, .3);
$shadow-light:              rgba(0, 0, 0, .2);
$shadow-lightest:           rgba(0, 0, 0, .1);

$breadcrumb-icon:           '\f105';

$form-input-border:         $glight;
$form-input-background:     $bwhite;
