/* big buttons, no circle */
.flickity-prev-next-button {
    width: 90px;
    height: 100px;
    padding: 0;
    background: transparent;

    &.previous { left: 0; }
    &.next { right: 0; }
    &.no-svg { color: white; }
    .arrow { fill: $bprimary; @include transition(fill, 0.15s, ease-in-out); }

    &:hover {
        background: transparent;
        .arrow { fill: $bwhite; }
    }

    &:disabled { display: none; }

    svg {
        left: 0%;
        top: 10%;
        width: 100%;
        height: 80%;
    }
}
