.header {
    position: absolute;
    top: 0; left: 90px; z-index: 2;
    min-width: 18em;
    text-align: left;

    &__controls {
        display: flex;
        align-items: flex-end;
    }

    .logo-container {
        a, img { display: block; }
        img { width: 100%; }
    }

    .btn--language { margin-left: .5em; }

    &__menu { margin: .75em 0; }
}
