.form-input {
    width: 100%;
    height: 3em;
    font-size: em(12);
    font-weight: $fwr;
    padding: 0 .5em;
    outline: none;
    border-radius: 0;
    border: 1px solid $form-input-border;
    background: $form-input-background;
    @include bb;
    &:hover { background: darken($form-input-background, 2%); border-color: darken($form-input-border, 10%); }
    &:focus {
        border-color: darken($form-input-border, 10%);
        background: lighten($form-input-background, 1%);
    }
    &:disabled {
        cursor: not-allowed;
        border: 1px solid $form-input-border;
        background: lighten($form-input-background, 1%);
    }

    &--fullwidth { width: 100%; }
    &--halfwidth { max-width: 50%; }
    &--width40 { max-width: 40%; }
    &--width70 { max-width: 70%; }

    &--error {
        position: relative;
        border-color: $bdanger;
        &:hover { border-color: $bdanger; }
    }
}

.form-textarea {
    @extend .form-input;
    height: 10em;
    padding: .25em .75em;
    line-height: 1.5em;
    resize: none;
}

.form-select {
    height: 3em;
    font-size: 1em;
    border: 1px solid $glight;
    border-radius: 2px;
    padding: 0;
    outline: none;

    &--square { border-radius: 1px; }
    &--white { border-color: $glight; background-color: $bwhite; }
}

.form-group {
    display: flex;
    flex-flow: row wrap;
    margin-bottom: 1em;
    &:last-child { margin-bottom: 0; }

    &__label {
        display: block;
        color: $bblack;
        font-size: em(17);
        font-family: $sfaf;
        line-height: 1.5em;

        &--note {
            position: absolute;
            left: 100%; bottom: 50%;
            margin: 0 0 -1.5em 1em;
            color: $bblack;
            font-size: em(14);
            font-family: $sfaf;
            line-height: 1em;
        }
    }

    &__module {
        &--w25 { max-width: 25%; }
        &--w35 { max-width: 35%; }
        &--w40 { max-width: 40%; }
        &--w45 { max-width: 45%; }
        &--w50 { max-width: 50%; }
        &--w60 { max-width: 60%; }
        &--w70 { max-width: 70%; }
        &--w75 { max-width: 75%; }
        &--w80 { max-width: 80%; }
    }

    &__btn { margin-top: .5em; }

    &__note {
        display: block;
        margin-left: .5em;
        color: tint($bblack, 30%);
        font-size: 90%;
        font-family: $sfaf;
        font-style: italic;
        text-align: center;
    }

    &__error-label {
        position: relative;
        margin: 1em 0;
        padding: .5em .75em;
        display: inline-block;
        color: $bwhite;
        font-size: em(12);
        font-family: $sfaf;
        line-height: 1em;
        text-transform: uppercase;
        border-radius: 3px;
        background-color: $bdanger;

        &:before {
            content: '\f06a';
            display: inline-block;
            margin-right: .5em;
            color: $bwhite;
            font-family: $ifaf;
        }

        &:after {
            content: '';
            position: absolute;
            bottom: 100%; left: 50%;
            margin-left: -.5em;
            @include triangle(up, .5em, $bdanger);
        }
    }

    label {
        flex: 1 auto;
        margin-left: 1em;
        &:first-child { margin-left: 0; }
    }
}

.btn-group {
    display: flex;
    justify-content: flex-end;
    margin: 1em 0;
}

.has-error {
    label, span { color: $bdanger; }
    input, select { border-color: tint($bdanger, 25%); }
}
