@media only screen and (max-width: 1280px) {
    body {
        font-size: 14px;
    }

    // .wrapper {
    //     width: 95%;
    //     margin: 0 2.5%;
    // }

    .header {
        min-width: 15em;


    }

    .page {
        position: relative;
        margin-left: 23em;
        background-color: $bwhite;
        &:before {
            position: fixed;
            top: 0; left: 0;
            width: 23em;
            height: 100%;
            display: block;
            content: '';
            background-color: $glighter;
        }
    }

}

@media only screen and (max-width: 1024px) {
    .header {
        left: 1em;
        min-width: 14em;
        max-width: 14em;
        &__controls {
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-end;
        }

        .btn--language { margin: 1em 0 0 0; }
    }

    .wrapper { width: 500px; }

    .page {
        width: 100%;
        margin-left: 0;
        padding-left: 16em;
        @include bb;
        &:before { width: 16em; }
    }

    .flickity-prev-next-button { display: none; }
}

@media only screen and (max-width: 900px) {
    .header {
        min-width: 11em;
        max-width: 11em;
    }

    .wrapper { width: 500px; }

    .page {
        padding-left: 13em;
        &:before { width: 13em; }
    }
}

@media only screen and (max-width: 767px) {
    .header {
        position: relative;
        left: 0;
        min-width: 100%;
        max-width: 100%;
        width: 100%;
        padding: 0 1em .25em 1em;
        background-color: none;
        @include bb;
        &__controls {
            flex-direction: row;
            align-items: flex-end;
            justify-content: space-between;
        }
    }

    .wrapper { width: 95%; }

    .page {
        width: 100%;
        padding-left: 0;
        &:before { display: none; }
    }

    .menu-container {
        margin-top: 1em;
        &__title {
            margin-top: 1px;
            &--mobile {
                cursor: pointer;
                background-color: $btn-primary;
                &:after {
                    display: inline-block;
                    float: right;
                    content: '\f0c9';
                    font-size: 130%;
                    font-family: $ifaf;
                }
            }
        }
        ul {
            display: none;
            li {
                margin: 0;
                background-color: $gdarker;
                a {
                    padding: .75em .75em;
                    background-color: transparent;
                }
            }
        }
    }
}
