// Buttons
.btn {
    display: inline-block;
    padding: .75em 1.25em;
    color: $bprimary;
    font-size: 1em;
    font-weight: $fwr;
    line-height: 1em;
    font-weight: $fwr;
    font-family: $bfaf;
    text-decoration: none;
    text-align: center;
    cursor: pointer;
    background: none;
    outline: none;
    border: none;
    border-radius: 0px;
    @include bb;
    @include transition(color, 0.15s, ease-in-out);
    @include transition(border-color, 0.15s, ease-in-out);
    @include transition(background-color, 0.15s, ease-in-out);

    &--fullwidth { width: 100%; display: block; }

    &--xs { padding: .5em .75em; font-size: em(12); border-radius: 2px; }
    &--s { padding: .5em .75em; font-size: em(11);}
    &--m { padding: .75em 1.25em; font-size: em(15); }
    &--l { font-size: em(23); }
    &--xl { font-size: em(25); }
    &--bold { font-weight: $fwb; }
    &--light { font-weight: $fwl; }
    &--wide { padding: .75em 2.25em; }
    &--fullwidth { width: 100%; }
    &--uppercase { text-transform: uppercase; }
    &--icon i { display: inline-block; margin-right: .5em; }
    &--sfaf { font-family: $sfaf; }

    &--primary {
        color: $bwhite;
        background: $btn-primary;
        &:hover {
            color: $bwhite;
            background: lighten($btn-primary, 10%);
        }
    }
}
