// Font declarations
@font-face {
    font-family: 'Klavika';
    src: url('../fonts/klavika-light-webfont.eot');
    src: url('../fonts/klavika-light-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/klavika-light-webfont.woff') format('woff'),
         url('../fonts/klavika-light-webfont.ttf') format('truetype');
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: 'Klavika';
    src: url('../fonts/klavika-regular-webfont.eot');
    src: url('../fonts/klavika-regular-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/klavika-regular-webfont.woff') format('woff'),
         url('../fonts/klavika-regular-webfont.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Klavika';
    src: url('../fonts/klavika-bold-webfont.eot');
    src: url('../fonts/klavika-bold-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/klavika-bold-webfont.woff') format('woff'),
         url('../fonts/klavika-bold-webfont.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
}

$fw-hairline:               100;
$fw-light:                  300;
$fw-regular:                400;
$fw-semibold:               500;
$fw-bold:                   700;
$fw-extrabold:              900;

$fwh:                       $fw-hairline;
$fwl:                       $fw-light;
$fwr:                       $fw-regular;
$fws:                       $fw-semibold;
$fwb:                       $fw-bold;
$fwe:                       $fw-extrabold;

// Base Font
$tc-base:                   $bblack;
$base-font-size:            16;
$base-font-sizepx:          16px;
$base-line-height:          1.5em;
$base-font-family:          'Klavika', 'Helvetica Neue', Helvetica, Arial, sans-serif;
$base-font-weight:          $fw-regular;
$bfaf:                      $base-font-family;

$icon-font-family:          'FontAwesome';
$ifaf:                      $icon-font-family;

// Secondary Font
$secondary-font-size:       $base-font-size;
$secondary-font-family:     'Helvetica Neue', Helvetica, Arial, sans-serif;
$secondary-line-height:     $base-line-height;
$sfaf:                      $secondary-font-family;

// Fixed Font
$fixed-font-size:           85%;
$fixed-font-family:         monospace;
$fixed-line-height:         $base-line-height;

// Font sizes
@function em($target, $context: $base-font-size) {
    @return ($target / $context) * 1em;
}
