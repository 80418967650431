body {
    background: $body-bgc;
    font-family: $base-font-family;
    font-size: $base-font-sizepx;
    color: $tc-base;
    background: $body-bgc;
    text-align: center;
    overflow-y: scroll;
}

.wrapper {
    width: $base-wrapper;
    position: relative;
    margin: 0 auto;
    text-align: left;
}

.img-container {
    img { width: 100%; }
}